<template>
	<v-dialog :value="dialog" scrollable max-width="550px" persistent>
		<AppDialog
			:heading="{
				label: 'app.collaborate',
			}"
			:action="{
				label: 'app.collaborate',
				event: 'submit',
			}"
			@close="$emit('close')"
			@submit="submit()"
		>
			<template v-slot:dialog-content>
				<v-form ref="form" class="mt-5">
					<v-text-field
						v-model.trim="board_id"
						:rules="[rules.required]"
						color="greenAccent2"
						background-color="white"
						outlined
						:label="$t('app.type_collaboration_id')"
					>
					</v-text-field>
				</v-form>

				<!-- Instructions in detail -->
				<div v-html="$t('app.collaboration_id_desc')" class="subtitle-1"></div>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import rules from "@/utils/validation";
import AppDialog from "@/components/ui/AppDialog.vue";

export default {
	name: "CollaborateBoardDialog",

	props: {
		dialog: {
			required: true,
			type: Boolean,
		},
	},

	data() {
		return {
			rules,
			board_id: null,
		};
	},

	components: {
		AppDialog,
	},

	methods: {
		async submit() {
			try {
				// Validate the form
				if (!this.$refs.form.validate()) return;

				// If validate, then emit event to confirm to proceed.
				this.$emit("confirm", this.board_id.replace(/\s/g, ""));

				// Reset the form
				this.$refs.form.reset();
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>
