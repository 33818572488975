<template>
	<v-card class="wr_grey_1" flat>
		<div class="mt-4">
			<v-card
				v-for="(item, index) in features"
				:key="index"
				width="300"
				height="220"
				:class="[
					'card-wr cursor-wr d-inline-block overflow-hidden ma-5',
					{ info: item.img == 'plus' },
				]"
				@click="$emit('handle-function-call', item.method_name)"
			>
				<v-row no-gutters align="center" justify="center" class="fill-height">
					<v-col align="center">
						<v-card-text>
							<v-icon v-if="item.img == 'plus'" size="70" color="white">
								$vuetify.icons.values.plus
							</v-icon>
							<v-img
								v-else
								width="70"
								:src="require(`@/assets/images/${item.img}.png`)"
								:class="{ 'vertical-transform-wr': $vuetify.rtl }"
							></v-img>
						</v-card-text>
						<v-card-text
							:class="[
								'title font-weight-bold text-truncate',
								{ 'white--text': item.img === 'plus' },
							]"
						>
							{{ $t(item.label) }}
						</v-card-text>
					</v-col>
				</v-row>
			</v-card>
		</div>
	</v-card>
</template>

<script>
export default {
	name: "AppFeaturesTheme",

	props: {
		features: {
			required: true,
			type: Array,
		},
	},
};
</script>

<style scoped>
.card-wr {
	transition: 0.4s !important;
}

.card-wr:hover {
	border: 3px solid var(--v-greenAccent2-base);
	transform: scale(1.1);
}
</style>
