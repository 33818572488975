<template>
	<v-dialog :value="dialog" scrollable width="550px" persistent>
		<AppDialog
			v-model="loading"
			:heading="{
				label: 'app.name_mind_map',
			}"
			:action="{
				label: 'app.save',
				event: 'save',
			}"
			@close="$emit('close')"
			@save="validate()"
		>
			<template v-slot:dialog-content>
				<v-form ref="form">
					<v-text-field
						v-model="name"
						:rules="[rules.required]"
						color="greenAccent2"
						background-color="white"
						outlined
						:label="$t('app.name')"
					>
					</v-text-field>
				</v-form>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import rules from "@/utils/validation";
import AppDialog from "@/components/ui/AppDialog.vue";

export default {
	name: "MindmapNameDialog",

	props: {
		dialog: {
			required: true,
			type: Boolean,
		},
	},

	data() {
		return {
			rules,
			loading: false,
			name: null,
		};
	},

	components: {
		AppDialog,
	},

	methods: {
		validate() {
			try {
				// Validate the form
				if (!this.$refs.form.validate()) return;

				this.loading = true;

				// If validate, then emit event to confirm to proceed.
				this.$emit("name-created", this.name, (reset) => {
					this.loading = false;
					if (reset) {
						this.$refs.form.reset();
					}
				});
			} catch (error) {
				this.loading = false;
				this.$announce.error(error);
			}
		},
	},
};
</script>
