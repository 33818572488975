import { mapGetters } from "vuex";
import MindmapNameDialog from "@/components/shared/MindmapNameDialog.vue";
import CollaborateBoardDialog from "@/components/shared/CollaborateBoardDialog";
import AppFeaturesTheme from "@/components/ui/AppFeaturesTheme.vue";
import { v4 as uuidv4 } from "uuid";

export const toolsWizardMapMixin = {
	data() {
		return {
			dialog: false,
			collaborate_dialog: false,
		};
	},

	components: {
		MindmapNameDialog,
		CollaborateBoardDialog,
		AppFeaturesTheme,
	},

	computed: {
		...mapGetters({
			isMentor: "user/isMentor",
		}),
	},

	methods: {
		handleFunctionCall(method_name) {
			this[method_name]();
		},

		openNewMapDialog() {
			this.dialog = true;
		},

		openCollaborationDialog() {
			this.collaborate_dialog = true;
		},

		async create(map_name, cb) {
			try {
				await this.$store.dispatch("wizardMindmap/create", {
					category: this.$defines.TOOLS_CATEGORY,
					type: this.$defines.WHITEBOARD_TYPES.WIZARD_MINDMAP,
					uuid: uuidv4(),
					name: map_name,
				});

				cb();

				this.dialog = false;

				await this.redirectToList();
			} catch (error) {
				cb(false);
				this.$announce.error(error);
			}
		},

		redirectToList() {
			try {
				this.$loader.start();

				this.$router.push({
					name: this.isMentor
						? "MentorWizardMindmapsList"
						: "WizardMindmapsList",
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async redirectToMap(id) {
			try {
				this.$loader.start();

				let response = await this.$store.dispatch("wizardMindmap/get", {
					uuid: id,
				});

				if (!response || !Object.keys(response).length) {
					throw new Error("app.ntfy.err.invalid_board_id", {
						cause: "werCustom",
					});
				}

				if (!response.is_sharing_active) {
					throw new Error("app.ntfy.err.not_activated_for_sharing", {
						cause: "werCustom",
					});
				}

				this.$router.push({
					name: this.isMentor
						? "MentorWizardMindmapExplore"
						: "WizardMindmapExplore",
					params: {
						id,
					},
					query: {
						collaborative_map: true,
					},
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
